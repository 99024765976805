import React from 'react'
import ContactForm from '../components/professionalservices/ContactForm'

export default function ProfessionalService() {
  return (
    <div className="h-[100vh] bg-background p-4">
     <ContactForm/>
    </div>
  )
}
